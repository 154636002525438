/* business start */
.business {
	.col-grid {
		padding-top: 24px; }
	.typo-list {
		margin-bottom: 15px;
		p {
			line-height: 1.25;
			margin-bottom: 8px;
			@include fw(300); }
		b {
			@include fw(500); }
		h6 {
			@include fw(400); } }
	.full-img {
		min-width: 100%;
		min-height: 100%; } }

/* business end */
