/* footer start */
.footer {
	background-color: transparent;
	padding-top: 0;
	padding-bottom: 30px;
	@include max767 {
		background-color: lighten($grey, 3%); }
	.contact {
		span {
			@include max767 {
				display: block; } } } }

.copy {
	@include fz(9);
	@include fw(700);
	@include font-main;
	line-height: 1.2;
	color: $text-nav;
	@include ttu; }

/* footer end */
