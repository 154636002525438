/* margins start */
.mb10 {
	margin-bottom: 10px; }

.mb12 {
	margin-bottom: 12px; }

.mb15 {
	margin-bottom: 15px; }

.mb18 {
	margin-bottom: 18px; }

.mb20 {
	margin-bottom: 20px; }

.mb25 {
	margin-bottom: 25px; }

.mb30 {
	margin-bottom: 30px; }

.mb35 {
	margin-bottom: 35px;
	@include max767 {
		margin-bottom: 30px; } }

.mb40 {
	margin-bottom: 40px;
	@include max767 {
		margin-bottom: 25px; } }

.mb50 {
	margin-bottom: 50px;
	@include max991 {
		margin-bottom: 45px; }
	@include max767 {
		margin-bottom: 30px; } }

.mb80 {
	margin-bottom: 80px;
	@include max991 {
		margin-bottom: 60px; }
	@include max767 {
		margin-bottom: 40px; } }

.mb95 {
	margin-bottom: 95px;
	@include max991 {
		margin-bottom: 65px; }
	@include max767 {
		margin-bottom: 45px; } }

.introduction {
	.mb95 {
		@include minh600-maxh850 {
			margin-bottom: 6%; } }
	.mb80 {
		@include minh600-maxh850 {
			margin-bottom: 5%; } } }

.mb-user {
	margin-bottom: $space-user;
	@include max991 {
		margin-bottom: $space-tablet-user; }
	@include max767 {
		margin-bottom: $space-mob-user; } }

/* margins end */

/* paddings start */
.pl50 {
	padding-left: 50px;
	@include max991 {
		padding-left: 35px; } }

.px0 {
	@include px(0); }

.px50 {
	@include px(50px);
	@include max767 {
		@include px(35px); } }

.px55 {
	@include px(55px);
	@include max991 {
		@include px(40px); }
	@include max767 {
		@include px(40px); } }

.px60 {
	@include px(60px);
	@include max767 {
		@include px(45px); } }

.px65 {
	@include px(65px);
	@include max991 {
		@include px(45px); } }

.px80 {
	@include px(80px);
	@include max991 {
		@include px(50px); } }

/* main gap styles */
.container {
	@include mx(auto);
	width: 100%;
	max-width: $container-width; }

.container-small {
	max-width: $container-small;
	@include mx(auto);
	width: 100%;
	@include px(15px); }

.container-half {
	width: 100%;
	max-width: 600px;
	@include max767 {
		max-width: 100%;
		@include px(15px); } }

.container-550 {
	max-width: 550px;
	@include px(35px);
	@include max767 {
		max-width: 100%;
		@include px(15px); } }

.no-gutters {
	.col-grid {
		&:nth-child(2n) {
			.container-half {
				margin-right: auto;
				@include max767 {
					@include mx(auto); } } }
		&:nth-child(2n+1) {
			.container-half {
				margin-left: auto;
				@include max767 {
					@include mx(auto); } } } }

	&.row-order {
		.col-grid {
			&:nth-child(2n+1) {
				.container-half {
					margin-right: auto;
					margin-left: 0;
					@include max767 {
						@include mx(auto); } } }
			&:nth-child(2n) {
				.container-half {
					margin-left: auto;
					margin-right: 0;
					@include max767 {
						@include mx(auto); } } } } } }

.container-user {
	@include mx(auto);
	max-width: ($content-user + $space-user);
	@include px($space-user / 2);
	@media (max-width: 991px) {
		max-width: ($content-user + $space-tablet-user);
		@include px($space-tablet-user / 2); }
	@media (max-width: 767px) {
		max-width: ($content-user + $space-mob-user);
		@include px($space-mob-user / 2); } }

.row-user {
	@include mx($space-user / -2);
	@media (max-width: 991px) {
		@include mx($space-tablet-user / -2); }
	@media (max-width: 767px) {
		@include mx($space-mob-user / -2); } }

.col-user {
	@include px($space-user / 2);
	@media (max-width: 991px) {
		@include px($space-tablet-user / 2); }
	@media (max-width: 767px) {
		@include px($space-mob-user / 2); } }

/* main gap styles end */


