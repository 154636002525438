/* heading styles start */
@include hd {
	@include font-main;
	@include m(0); }

h1 {
	@include fz(43);
	@include fw(500);
	line-height: 1.1;
	margin-bottom: 20px;
	@include ttu;
	@include max991 {
		@include fz(36); }
	@include max767 {
		@include fz(28); }
	small {
		display: block;
		@include fz(32);
		@include fw(200);
		@include max991 {
			@include fz(28); }
		@include max767 {
			@include fz(26); } } }

h2 {
	@include fz(42);
	@include fw(900);
	color: $primary;
	@include ttu;
	@include max991 {
		@include fz(36); }
	@include max767 {
		@include fz(28); } }

h3 {
	@include fz(28);
	@include fw(700);
	@include ttu;
	@include max991 {
		@include fz(26); }
	@include max767 {
		@include fz(24); } }

// h4
// 	+fz(20)

h5 {
	@include fz(24);
	margin-bottom: 5px;
	@include max991 {
		@include fz(22); }
	&.large {
		@include fz(26);
		@include max991 {
			@include fz(24); } } }

h6 {
	@include fz(20);
	margin-bottom: 5px; }

p {
	@include fz(14);
	margin-bottom: 14px;
	@include max767 {
		@include fz(16); }
	&.huge {
		@include fz(28);
		@include fw(400);
		line-height: 1.2;
		@include max991 {
			@include fz(22); }
		@include max767 {
			@include fz(20); } } }

.note {
	@include fz(11); }

address {
	p {
		@include m(0); }
	a {
		margin-left: 5px;
		margin-right: 5px; } }

a {
	@include f {
		outline: none!important; } }

/* heading styles end */


/* Font-weight styles start*/
.fw900 {
	font-weight: 900; }

.fw700 {
	font-weight: 700; }

.fw500 {
	font-weight: 500; }

.fw400 {
	font-weight: 400; }

.fw300 {
	font-weight: 300; }

.fw200 {
	font-weight: 200; }

/* Font-weight styles end*/



