/* modal start */
.modal-dialog {
	width: 90%;
	@include mx(auto);
	@include min576 {
		max-width: 700px; } }

.modal-content {
	@include r(0); }

.modal-header {
	@extend .d-flex;
	@extend .flex-wrap;
	position: relative;
	padding-top: 50px;
	padding-bottom: 10px;
	.close {
		position: absolute;
		top: 12px;
		right: 10px;
		width: 30px;
		height: 30px;
		display: block;
		border: 1px solid $black;
		opacity: 0.7;
		margin: 0;
		@include p(5px);
		@include ab {
			@include ct;
			width: 2px;
			height: 20px;
			display: block;
			background-color: $black;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -10px;
			margin-left: -1px; }
		@include a {
			transform: rotate(45deg); }
		@include b {
			transform: rotate(-45deg); }
		@include h {
			opacity: 1; } } }

.modal-title {
	color: $primary;
	line-height: 1.1;
	@include min992 {
		max-width: 90%; } }

.modal-body {
	padding-top: 10px;
	@extend .d-flex;
	@extend .flex-wrap;
	overflow-y: scroll;
	padding-bottom: 50px;
	p {
		line-height: 1.25;
		margin-bottom: 7px; } }


.modal-header,
.modal-body,
.modal-footer {
	@include px(60px);
	border: none;
	@include max991 {
		@include px(40px); }
	@include max991 {
		@include px(20px); } }

.modal-footer {
	margin-top: -20px;
	padding-top: 0;
	padding-bottom: 50px; }

.modal-photo-wrap {
	@include mx(-60px);
	width: calc(100% + 120px); }

.modal-photo {
	display: block;
	max-width: 100%;
	@include mx(auto); }

/* modal end */
