/* international start */
.international {
	padding-bottom: 0;
	.container-half {
		padding-top: 35px;
		padding-bottom: 20px; }
	p {
		margin-bottom: 8px; } }

/* international end */
