/* deontology start */
.deontology {
	padding-bottom: 0;
	.section-photo {
		@extend .order-md-2; }
	.container-half {
		padding-top: 30px;
		padding-bottom: 20px; }
	p {
		margin-bottom: 7px;
		@include fw(300); } }

/* deontology end */
