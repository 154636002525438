
// MIXINS

@mixin fw($value) {
	font-weight: $value; }

// rounded corners
@mixin r($radius) {
	border-radius: $radius; }


// HOVER AND FOCUS STATE
@mixin hf {
	&:hover, &:focus {
		@content; } }

// Hover
@mixin h {
	&:hover {
		@content; } }

// Focus
@mixin fc {
	&:focus {
		@content; } }

@mixin in {
	display: inline-block;
	vertical-align: top; }

@mixin im {
	display: inline-block;
	vertical-align: middle; }

@mixin ts($transition) {
	-webkit-transition: $transition;
	transition: $transition; }

@mixin ct {
	content: '';
	display: block; }

@mixin ln($color1, $color2) {
	background: -webkit-linear-gradient($color1, $color2);
	background: linear-gradient($color1, $color2); }

@mixin lnl($colors) {
	background: -webkit-linear-gradient(left, $colors);
	background: linear-gradient(to right, $colors); }

@mixin lnr($colors) {
	background: -webkit-linear-gradient(right, $colors);
	background: linear-gradient(to left, $colors); }

@mixin radial($color1, $color2) {
	background-image: $color2;
	background-image: -webkit-radial-gradient($color1, $color2);
	background-image: radial-gradient($color1, $color2); }

@mixin tf($value) {
	-webkit-transform: $value;
	-ms-transform: $value;
	transform: $value; }

// MAIN TRANSITION EFFECT FOR CSS ANIMATIONS
@mixin tm {
	@include ts(all $transition-time ease-out); }


// dots when text is longer than element
@mixin dots {
	display: block;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

@mixin mxa {
	margin-right: auto;
	margin-left: auto; }

// text-uppercase
@mixin ttu {
	text-transform: uppercase; }

// underline
@mixin tdu {
	text-decoration: underline; }

// horizontally centered element
@mixin hc {
	display: block;
	margin-right: auto;
	margin-left: auto; }

@mixin act {
	&:active,
	&.active {
		@content; } }

// all but not last
@mixin nl {
	&:not(:last-child) {
		@content; } }

// all but not first
@mixin nf {
	&:not(:first-child) {
		@content; } }

// this last child
@mixin l {
	&:last-child {
		@content; } }

// this first child
@mixin f {
	&:first-child {
		@content;
		@content; } }

// in this last item
@mixin li {
	*:last-child {
		@content; } }

// in this first item
@mixin fi {
	*:first-child {
		@content; } }

@mixin abs0 {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }


// BREAKPOINTS

@mixin min1600 {
	@media print, screen and (min-width: 1600px) {
		@content; }
	/*min-width: 1600px END*/ }

@mixin min1200 {
	@media print, screen and (min-width: 1200px) {
		@content; }
	/*min-width: 1200px END*/ }

@mixin min1024 {
	@media print, screen and (min-width: 1024px) {
		@content; }
	/*min-width: 1024px END*/ }

@mixin min992 {
	@media print, screen and (min-width: 992px) {
		@content; }
	/*min-width: 992px END*/ }

@mixin min768 {
	@media print, screen and (min-width: 768px) {
		@content; }
	/*min-width: 768px END*/ }

@mixin min640 {
	@media print, screen and (min-width: 640px) {
		@content; }
	/*min-width: 640px END*/ }

@mixin min576 {
	@media print, screen and (min-width: 576px) {
		@content; }
	/*min-width: 576px END*/ }




@mixin max1365 {
	@media print, screen and (max-width: 1365px) {
		@content; }
	/*max-width: 1365pxEND*/ }

@mixin max1199 {
	@media print, screen and (max-width: 1199px) {
		@content; }
	/*max-width: 1199pxEND*/ }

@mixin max1023 {
	@media print, screen and (max-width: 1023px) {
		@content; }
	/*max-width: 1023pxEND*/ }

@mixin max991 {
	@media print, screen and (max-width: 991px) {
		@content; }
	/*max-width: 991px END*/ }

@mixin max767 {
	@media print, screen and (max-width: 767px) {
		@content; }
	/*max-width: 767px END*/ }

@mixin max639 {
	@media print, screen and (max-width: 639px) {
		@content; }
	/*max-width: 639px END*/ }

@mixin max575 {
	@media print, screen and (max-width: 575px) {
		@content; }
	/*max-width: 575px END*/ }

@mixin max370 {
	@media print, screen and (max-width: 370px) {
		@content; }
	/*max-width: 370px END*/ }


@mixin min768-max1199 {
	@media print, screen and (min-width: 768px) and (max-width: 1199px) {
		@content; }
	/*(min-width: 768px) and (max-width: 1199px) END*/ }

@mixin min992-max1199 {
	@media print, screen and (min-width: 992px) and (max-width: 1199px) {
		@content; }
	/*(min-width: 992px) and (max-width: 1199px) END*/ }

@mixin min768-max991 {
	@media print, screen and (min-width: 768px) and (max-width: 991px) {
		@content; }
	/*(min-width: 768px) and (max-width: 991px) END*/ }

@mixin min640-max1023 {
	@media print, screen and (min-width: 640px) and (max-width: 1023px) {
		@content; }
	/*(min-width: 640px) and (max-width: 1023px) END*/ }


@mixin minh600-maxh799 {
	@media print, screen and (min-height: 600px) and (max-height: 799px) {
		@content; } }

@mixin minh600-maxh850 {
	@media print, screen and (min-height: 600px) and (max-height: 850px) {
		@content; } }

@mixin minh907 {
	@media print, screen and (min-height: 907px) {
		@content; } }

@mixin maxh599 {
	@media print, screen and (max-height: 599px) {
		@content; } }

@mixin w768-maxh1366 {
	@media print, screen and (width: 768px) and (min-height: 1024px) and (max-height: 1366px) {
		@content; } }

@mixin w1024-maxh1366 {
	@media print, screen and (width: 1024px) and (min-height: 1024px) and (max-height: 1366px) {
		@content; } }



// selected text properties
@mixin selection($color) {
	::moz-selection {
	    background: $color; }

	::selection {
	    background: $color; } }

@mixin fz($font-size) {
	/*#{$font-size}px*/
	font-size: $font-size + px; }

@mixin keys($name) {
	@-webkit-keyframes #{$name} {
		@content; }

	@keyframes #{$name} {
		@content; } }

@mixin nw {
	display: inline-block;
	white-space: nowrap; }

// BUTTONS
// BOOTSTRAP REGULAR BUTTON
@mixin btn($name, $color, $bgColor) {

	.btn-#{$name} {
		color: $color!important;
		background-color: $bgColor;
		border-color: $bgColor; }

	.btn-#{$name}:hover {
		color: $color!important;
		background-color: darken($bgColor, 2%);
		border-color: darken($bgColor, 2%); }

	.btn-#{$name}:focus,
	.btn-#{$name}.focus,
	.btn-#{$name}:active,
	.btn-#{$name}.active,
	.open > .btn-#{$name}.dropdown-toggle,
	.btn-#{$name}:active:hover,
	.btn-#{$name}:active:focus,
	.btn-#{$name}:active.focus,
	.btn-#{$name}.active:hover,
	.btn-#{$name}.active:focus,
	.btn-#{$name}.active.focus,
	.open > .btn-#{$name}.dropdown-toggle:hover,
	.open > .btn-#{$name}.dropdown-toggle:focus,
	.open > .btn-#{$name}.dropdown-toggle.focus {
		color: $color!important;
		background-color: darken($bgColor, 4%);
		border-color: darken($bgColor, 4%); }

	.btn-#{$name}.disabled:focus,
	.btn-#{$name}.disabled.focus,
	.btn-#{$name}:disabled:focus,
	.btn-#{$name}:disabled.focus {
		background-color: $bgColor;
		border-color: $bgColor; }

	.btn-#{$name}.disabled:hover,
	.btn-#{$name}:disabled:hover {
		background-color: $bgColor;
		border-color: $bgColor; } }

// BOOTSTRAP OUTLINE BUTTON
@mixin btnOutline($name, $color, $hoverColor) {

	.btn-outline-#{$name} {
		color: $color;
		background-image: none;
		background-color: transparent;
		border-color: $color; }

	.btn-outline-#{$name}:hover {
		color: $hoverColor;
		background-color: transparent;
		border-color: $hoverColor; }

	.btn-outline-#{$name}:focus,
	.btn-outline-#{$name}.focus {
		color: $hoverColor;
		background-color: transparent;
		border-color: $hoverColor; }

	.btn-outline-#{$name}:active,
	.btn-outline-#{$name}.active,
	.open > .btn-outline-#{$name}.dropdown-toggle {
		color: $hoverColor;
		background-color: transparent;
		border-color: $hoverColor; }

	.btn-outline-#{$name}:active:hover,
	.btn-outline-#{$name}:active:focus,
	.btn-outline-#{$name}:active.focus,
	.btn-outline-#{$name}.active:hover,
	.btn-outline-#{$name}.active:focus,
	.btn-outline-#{$name}.active.focus,
	.open > .btn-outline-#{$name}.dropdown-toggle:hover,
	.open > .btn-outline-#{$name}.dropdown-toggle:focus,
	.open > .btn-outline-#{$name}.dropdown-toggle.focus {
		color: darken($hoverColor, 5%);
		background-color: transparent;
		border-color: darken($hoverColor, 5%); }

	.btn-outline-#{$name}.disabled:focus,
	.btn-outline-#{$name}.disabled.focus,
	.btn-outline-#{$name}:disabled:focus,
	.btn-outline-#{$name}:disabled.focus {
		border-color: lighten($color, 5%); }

	.btn-outline-#{$name}.disabled:hover,
	.btn-outline-#{$name}:disabled:hover {
		border-color: lighten($color, 5%); } }

// placeholder
@mixin ph($color) {
	input,
	textarea {
		&::-webkit-input-placeholder {
			color: $color; }
		&::-moz-placeholder {
			color: $color; }
		&:-ms-input-placeholder {
			color: $color; }
		&::placeholder {
			color: $color; } } }

// placeholder this
@mixin pht($color) {
	&::-webkit-input-placeholder {
		color: $color; }
	&::-moz-placeholder {
		color: $color; }
	&:-ms-input-placeholder {
		color: $color; }
	&::placeholder {
		color: $color; } }

@mixin a {
	&:after {
		@content; } }

@mixin b {
	&:before {
		@content; } }

@mixin ab {
	&:after,
	&:before {
		@content; } }

@mixin hd {
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
		@content; } }


@mixin p($value) {
	padding: $value; }

@mixin px($value) {
	padding-right: $value;
	padding-left: $value; }

@mixin py($value) {
	padding-top: $value;
	padding-bottom: $value; }

@mixin m($value) {
	margin: $value; }

@mixin mx($value) {
	margin-right: $value;
	margin-left: $value; }

@mixin my($value) {
	margin-top: $value;
	margin-bottom: $value; }

@mixin on {
	&:focus {
		outline: none; } }



// fonts

@mixin font-main {
	font-family: "Roboto"; }




