body {
	position: relative;
	margin-right: auto;
	margin-left: auto;
	@include font-main;
	@include fz(14);
	background-color: $body-bg;
	color: $text-main;
	padding-top: 68px; }

html,
body {
	min-height: 100vh;
	position: relative; }

.nowrap {
	@include nw; }

.ttu {
	@include ttu; }

.typo-list {
	@include p(0);
	@include m(0);
	list-style: none;
	li {
		@extend .text-left;
		position: relative;
		padding-left: 10px;
		@include b {
			@include ct;
			position: absolute;
			top: 6px;
			left: 0;
			width: 7px;
			height: 7px;
			background-color: $primary;
			@include r(50%); } }
	p {
		margin-bottom: 0; }
	b {
		@extend .d-block; } }

.typo-list-white {
	li {
		@include b {
			background-color: $white; } }
	p {
		color: $white; } }


address {
	p, a {
		@include font-main;
		@include fz(14);
		@include fw(300);
		line-height: 1.2; } }

article {
	p {
		line-height: 1.25; }
	.typo-list {
		padding-left: 50px;
		@include max767 {
			padding-left: 30px; } } }

.w100p {
	display: block;
	width: 100%; }

img.filtered {
	-webkit-filter: invert(.8);
	filter: invert(.8);
	-webkit-filter: sepia(95%) saturate(186%) hue-rotate(162deg) contrast(0.9);
	filter: sepia(95%) saturate(186%) hue-rotate(162deg) contrast(0.9); }

.section {
	padding-top: 20px;
	padding-bottom: 3px;
	overflow: hidden; }

.section-photo {
	position: relative;
	overflow: hidden;
	@include max767 {
		height: 300px;
		order: 2; }
	.absolute-image {
		@include max767 {
			max-width: 100%; } } }

.full-image {
	display: block;
	min-width: 100%;
	min-height: 100%; }

.absolute-image {
	position: absolute;
	top: 50%;
	left: 50%;
	@include tf(translate(-50%, -50%));
	@extend .full-image; }

@include selection(lighten($info, 45%));
