/*header start */
.header {
	background-color: $header-bg;
	padding-top: 25px;
	padding-bottom: 5px;
	height: 68px;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	@include max767 {
		padding-top: 8px;
		padding-bottom: 7px; }
	@include a {
		@include ct;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 2px;
		@include ln($primary, transparent); } }

/* header end */
