$white: 	#fff;
$grey: 		#ededed;
$dirty_grey: #e9eef2;

$primary: 	#006f9e;
$dark_primary: #005578;
$info: 		#009fe3;
$dark_info: #008ab7;

$text-nav: #1d1d1b;
$text-main: #000;
$text_link: #0075bf;

$header-bg: $white;

$container-width: 	1064px;
$container-small:	740px;
$content-user: 		1030px;
$space-user: 		55px;
$space-tablet-user:	25px;
$space-mob-user:	15px;

// Animations Time
$transition-time: 0.3s;







