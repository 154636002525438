/* bottom-nav start */
.bottom-nav {
	@include max767 {
		padding-top: 10px;
		background-color: lighten($grey, 3%); }
	.navbar-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .flex-row;
		@extend .justify-content-center; }
	.nav-item {
		@include max767 {
			width: 100%;
			display: block;
			text-align: center; } }
	.nav-link {} }

/* bottom-nav end */
