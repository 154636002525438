/* navbar start */
.navbar {
	padding: 0;
	margin-bottom: 0;
	position: relative;
	@include max767 {
		justify-content: flex-end; } }

.navbar-nav {
	@include p(0);
	.nav-item {
		.nav-link {
			@include min768 {
				@include px(12px);
				@include py(10px); }
			&.active {
				color: $primary; } } } }

.nav-item {}

.nav-link {
	@extend .d-block;
	@include fz(12);
	@include font-main;
	@include fw(700);
	@include ttu;
	@include px(10px);
	@include py(5px);
	color: $text-nav;
	@include h {
		color: $primary; }
	@include max767 {
		display: inline-block!important;
		@include py(8px); } }

.navbar-collapse {
	@include min768 {
		display: flex;
		z-index: 150;
		justify-content: center; }
	@include max767 {
		margin-right: -15px;
		margin-left: -15px;
		flex-basis: calc(100% + 30px); }
	.navbar-nav {
		@include max767 {
			background-color: $white;
			margin-right: -15px;
			margin-left: -15px;
			position: relative;
			z-index: 150; } }
	.nav-item {
		@include max767 {
			text-align: center; } } }

.navbar-toggler {
	display: block;
	padding: 10px 0;
	border: none;
	border-radius: 0px;
	outline: none;
	cursor: pointer;
	@include fc {
		outline: none; }
	.icon-bar {
		display: block;
		height: 3px;
		width: 30px;
		margin: 6px auto;
		background-color: $primary;
		@include tm; }
	&[aria-expanded="true"] {
		.icon-bar1 {
			margin-top: 4px;
			@include tf(rotate(135deg)); }
		.icon-bar2 {
			opacity: 0; }
		.icon-bar3 {
			margin-top: -18px;
			@include tf(rotate(-135deg)); } } }

/* navbar end */




