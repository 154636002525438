/* prefooter start */
.prefooter {
	padding-top: 10px;
	@extend .text-center;
	address {
		margin-bottom: 5px;
		p, a, span {
			color: $text-main;
			line-height: 1.3;
			@include fw(500); }
		a {
			@include h {
				cursor: pointer; } } } }

/* prefooter end */
