/* fees start */
.fees {
	padding-bottom: 8px;
	.grey-bg {
		padding-top: 20px;
		padding-bottom: 8px; }
	article {
		p {
			margin-bottom: 8px; } } }

/* fees end */
