/* buttons start */
.btn {
	@extend .d-inline-block;
	@include r(0);
	@include font-main;
	@include fz(20);
	@include fw(700);
	text-transform: uppercase;
	line-height: 1.2;
	@include px(15px);
	@include py(8px);
	min-width: 140px;
	@include h {
		cursor: pointer; } }

@include btn(info, $white, $info);

.text-link {
	@extend .d-inline-block;
	color:  $text_link;
	@include tm;
	@include h {
		color:  $text_link; } }

a.text-link {
	@include tdu;
	@include h {
		color: $primary;
		cursor: pointer;
		@include tdu;
		@include tm; } }

.btn-next-absolute {
	position: absolute;
	z-index: 50;
	bottom: 35px;
	left: 50%;
	@include tf(translate(-50%, 0));
	@include min1200 {
		bottom: 20px; } }

.btn-next {
	display: inline-block;
	position: relative;
	width: 46px;
	height: 46px;
	@include r(50%);
	background-color: $primary;
	@include tm;
	@include b {
		@include ct;
		position: absolute;
		@include fz(40);
		top: 50%;
		margin-top: 2px;
		left: 50%;
		width: 24px;
		height: 19px;
		background-image: url(../img/btn-next.png);
		background-size: 50px 19px;
		background-position: top right;
		background-repeat: no-repeat;
		@include tf(translate(-50%, -50%)); }
	@include h {
		background-color: darken($primary, 10%);
		cursor: pointer;
		@include tm; }
	&.arrow-up {
		@include b {
			background-image: url(../img/btn-next.png);
			transform: translate(-50%, -50%) rotate(180deg);
			margin-top: 0px; } } }

.btn-next-white {
	background-color: $white;
	@include b {
		background-position: 0 0; }
	@include h {
		background-color: darken($primary, 10%);
		@include b {
			background-position: top right; } } }

button {
	display: inline-block;
	border: none;
	background-color: transparent;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
	-webkit-writing-mode: flex !important;
	cursor: pointer;
	@include h {
		cursor: pointer; }
	@include fc {
		outline: none;
		box-shadow: none; } }

/* buttons end */



