@import "variables";
@import "mixins";

/* bootstrap start */
@import "vendor/bootstrap/bootstrap.scss";
/* bootstrap end */

/* fonts start */
@import "vendor/fonts";
/* fonts end */

/* global start */
@import "global";
/* global end */

/* website comonents start */
@import "components/benefits.sass";
@import "components/bottom-nav.sass";
@import "components/business.sass";
@import "components/buttons.sass";
@import "components/colors.sass";
@import "components/course.sass";
@import "components/deontology.sass";
@import "components/fees.sass";
@import "components/footer.sass";
@import "components/gap.sass";
@import "components/header.sass";
@import "components/international.sass";
@import "components/introduction.sass";
@import "components/modal.sass";
@import "components/nav.sass";
@import "components/prefooter.sass";
@import "components/typo.sass";
/* website comonents end */
