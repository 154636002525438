/* benefits-wrap start */
.benefits {
	padding-top: 30px;
	@include max767 {
		padding-bottom: 20px; } }

.benefits-wrap {
	@extend .d-flex;
	@extend .align-items-stretch;
	margin-bottom: -15px;
	@include max767 {
		margin-bottom: 0; }
	.col-user {
		@extend .mb-user; }
	.card-item {
		@extend .d-flex;
		@extend .w-100;
		@extend .flex-column;
		@extend .align-items-start;
		height: 100%;
		min-height: 280px;
		@extend .text-white;
		@include ttu;
		@include px(30px);
		padding-top: 35px;
		padding-bottom: 20px;
		text-align: left;
		@include max991 {
			@include px(15px);
			min-height: 250px; }
		@include max767 {
			padding-top: 20px;
			min-height: 150px; }
		&.grey-bg {
			color: $primary;
			* {
				color: $primary; } }
		@include h {
			cursor: pointer; } } }

/* benefits-wrap end */
