/* introduction start */

.introduction {
	@extend .d-flex;
	@extend .flex-column;
	@extend .flex-wrap;
	@extend .align-items-stretch;
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: calc(100vh - 68px);
	@include min1200 {
		height: calc(100vh - 68px); }
	@include max767 {
		height: auto;
		min-height: calc(100vh - 68px); }
	@include maxh599 {
		height: auto;
		min-height: calc(100vh - 68px); }
	.row {
		@extend .align-items-stretch;
		@extend .h-100;
		min-height: 100%;
		* {}
		color: $white; }
	.typo-list {
		width: 100%;
		max-width: 400px;
		@include mx(auto);
		padding-left: 30px;
		li {
			padding-left: 15px;
			@include b {
				@include ct;
				position: absolute;
				top: 12px;
				left: 0;
				width: 4px;
				height: 4px;
				background-color: $white;
				@include r(50%); } }
		p {
			@include fz(20);
			@include fw(200);
			line-height: 1.6;
			@include l {
				@include m(0); }
			@include max991 {
				@include fz(18); } } }
	address {
		a, span {
			@include fw(200); } }
	.text-link {
		color: $white;
		text-decoration: none;
		@include h {
			text-decoration: underline;
			color: $white; } } }

.logo {
	display: block;
	img {
		@include max991 {
			width: 70px;
			height: auto; }
		@include minh600-maxh799 {
			height: 80px;
			width: auto; } } }

.lawyer-photo {
	@extend .d-flex;
	@extend .justify-content-end;
	@extend .align-items-start;
	@extend .primary-bg;
	background: $grey;
	background-image: url(../img/introduction-bg.jpg);
	background-position: top left;
	background-size: initial;
	background-repeat: repeat-x;
	overflow: hidden;
	z-index: 1;
	position: relative;
	overflow: hidden;
	@include max767 {
		background-image: none; }
	@include minh907 {
		background-size: auto 100%; }
	@include ab {
		@include ct;
		position: absolute;
		z-index: 0;
		top: 0;
		width: 50%;
		height: 100%;
		background-position: top left;
		background-size: auto 100%;
		background-repeat: repeat-x;
		overflow: hidden;
		display: none;
		@include max767 {
			display: block; } }
	@include a {
		left: 0;
		background-image: url(../img/introduction-bg.jpg); }
	@include b {
		left: 50%;
		background-image: url(../img/introduction-bg-right.jpg); }
	@include max767 {
		order: 2; }
	img {
		display: block;
		min-height: 100%;
		height: auto;
		width: auto;
		position: relative;
		z-index: 5;
		@include max991 {
			max-height: 650px; }
		@include max767 {
			@include mx(auto);
			max-height: 400px; }
		@include w768-maxh1366 {
			@include tf(translate(30%, 0)); }
		@include w1024-maxh1366 {
			@include tf(translate(20%, 0)); } } }

.lawyer-info {
	padding-top: 100px;
	padding-top: 70px;
	@extend .d-flex;
	@extend .flex-column;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .primary-bg;
	overflow: hidden;
	@include min1200 {
		padding-top: 30px;
 }		// padding-top: 20px
	@include minh600-maxh799 {
		padding-top: 10px;
		// padding-top: 0px
		height: 100%;
		overflow: auto; }
	@include max767 {
		padding-top: 40px;
 }		// padding-top: 20px
	@include maxh599 {
		padding-top: 20px;
 }		// padding-top: 0px
	.container-half {
		@extend .d-flex;
		@extend .flex-column;
		@extend .align-self-center;
		padding-top: 30px;
		padding-bottom: 20px;
		@include px(10px);
		@include max991 {
			padding-top: 20px; }
		@include max1199 {
			padding-top: 0; }
		@include maxh599 {
			padding-top: 0; } } }

@-moz-document url-prefix() {
	.lawyer-info {
		@include min1200 {
			padding-top: 0; }
		@include minh600-maxh850 {
			justify-content: flex-start !important;
			padding-top: 60px; }

		.container-half {
			@include minh600-maxh850 {
				align-self: flex-start !important; }
 } } }			// @extend .align-self-start


/* introduction end */

