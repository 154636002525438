/* colors start */
.text-white {
	color: $white;
	* {
		color: $white; } }

.primary-bg {
	background-color: $primary;
	@include tm; }
button.dark-primary-bg {
	@include h {
		background-color: darken($primary, 5%);
		@include tm; } }

.dark-primary-bg {
	background-color: $dark_primary;
	@include tm; }
button.dark-primary-bg {
	@include h {
		background-color: darken($dark_primary, 5%);
		@include tm; } }

.info-bg {
	background-color: $info;
	@include tm; }
button.info-bg {
	@include h {
		background-color: darken($info, 5%);
		@include tm; } }

.dark-info-bg {
	background-color: $dark_info;
	@include tm; }
button.dark-info-bg {
	@include h {
		background-color: darken($dark_info, 5%);
		@include tm; } }

.grey-bg {
	background-color: $dirty_grey;
	@include tm; }
button.grey-bg {
	@include h {
		background-color: darken($dirty_grey, 5%);
		@include tm; } }

/* colors end */

